import Handsontable from 'handsontable';
import { EventBus } from '@/patterns/event-bus.js';
import * as DataTableHelper from '@/lib/dataTableHelpers.js';
import store from '@/store';
import { MultiSelectEditor, MultiSelectRenderer } from '@/lib/handsontable/editors/multiselect';
import { ClientDataMapper } from '@/lib/data_mappers/client/client.js';
import { apolloClient } from '@/lib/apollo';

export const clients_constants = {
  HOT_TABLE_COLUMNS: [
    {
      value: 'id',
      title: 'ID',
      width: 50,
      readOnly: true
    },
    {
      value: 'name',
      title: 'Имя',
      width: 120,
      readOnly: true,
    },
    {
      value: 'comment',
      title: 'Комментарий',
      width: 140,
      readOnly: true
    },
    {
      value: 'phones',
      title: 'Телефон',
      width: 100,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value || value.length == 0) return;

        const arr = Array.from(value, el => el.phone);

        td.innerHTML = arr.join(', ');
      },
    },
    {
      value: 'tags',
      parentFieldName: 'client',
      title: 'Теги',
      width: 250,
      columnGroup: 'Клиент',
      formComponent: 'multiselect',
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value || value.length == 0) return;

        const arr = Array.from(value, el => el.name);

        td.innerHTML = arr.join(', ');
      }
    }
  ]
};
