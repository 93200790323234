<template>
  <div class="main">
    <div class="handsontable-wrap no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div>
        <filters v-bind:tableOptions="tableOptions"
                 v-bind:filters="listFilters"></filters>
        <div ref="clientsHotTable"
             class="clients-table"></div>
        <p v-if="!listClients && !isLoading" class="no-data">Данных не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import clients from '@/lib/data_mappers/clients/clients.js';
import DataLoader from '@/components/DataLoader.vue';
import Filters from '@/components/Filters.vue';
import { clients_constants } from '@/lib/handsontable/clients/table_constants.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      queryRes: null,
      isLoading: true,
      queryStatus: 'default',
    };
  },
  components: {
    DataLoader,
    Filters
  },
  computed: {
    ...mapGetters({
      listClients: 'clients/getClients',
      listFilters: 'clients/getFilters'
    }),

    tableOptions() {
      return clients_constants.HOT_TABLE_COLUMNS;
    }
  },
  methods: {
    updateState(payload) {
      this.isLoading = payload.isLoading;
    },
    restoreQueryStatus() {
      this.queryStatus = 'default';
    },
    loadClientsData() {
      this.$nextTick(() => {
        this.clientsInstance.loadClientsData(
          {
            query: {
              first: 50
            },
            table: {
              container: this.$refs.clientsHotTable
            }
          }
        )
      });
    },
    refreshData() {
      this.loadClientsData();
    }
  },
  async mounted() {
    this.loadClientsData();

    this.clientsInstance = clients;
    this.clientsInstance.callbackStatus = this.updateState.bind(this);
  }
};
</script>
