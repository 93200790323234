import gql from 'graphql-tag';

const CLIENT_FIELDS = `
  id
  comment
  name
  phones {
    phone
    canSendSms
    comment
  }
  tags {
    id
    isActive
    name
    useScopes
  }
`;

export const query_clients = gql`
  query query_clients(
    $cursor: String,
    $first: Int,
    $fields: [InternalAdmin__Client__FilterField!],
    $orders: [InternalAdmin__Client__Order!],
  ) {
    clients(
      first: $first,
      after: $cursor,
      filter: {
        fields: $fields,
      }
      orders: $orders,
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      nodes {
        ${CLIENT_FIELDS}
      }
    }
  }
`;

export const mutation_client_merge = gql`
  mutation mutation_client_merge(
    $id: ID!,
    $withId: ID!
  ) {
    clientMerge(
      input: {
        id: $id,
        withId: $withId
      }
    ) {
      client {
        ${CLIENT_FIELDS}
      }
      status
    }
  }
`;
