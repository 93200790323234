import { clients_constants } from '@/lib/handsontable/clients/table_constants.js';
import clients from '@/lib/data_mappers/clients/clients.js';
import Handsontable from 'handsontable';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import store from '@/store';
import grid_constants from '@/assets/data/grid_constants';

registerLanguageDictionary(ruRU);

const BASE_COLUMN_WIDTH = 140;
const HEADER_HEIGHT = 51;
const TABLE_HEIGHT = window.innerHeight - HEADER_HEIGHT - 20;

export class ClientsHotTable {
  #container = null;
  #data = null;
  #hotInstance = null;
  #filterFields = ['id'];
  #clientColumns = clients_constants.HOT_TABLE_COLUMNS;
  #hasNextPage = null;
  #endCursor = null;
  #listFilters = [];

  fixedColumnsLeft() {
    return document.body.clientWidth > 767 ? 1 : 0;
  }

  colWidths() {
    const arr = [];

    this.#clientColumns.forEach((el) => {
      const objWidth = el.width || BASE_COLUMN_WIDTH;

      arr.push(objWidth);
    });

    return arr;
  }

  loadMore() {
    clients.isLoading = true;
    clients.fireCallbackDone();
    const q = {
      first: 50,
      cursor: this.#endCursor
    }

    clients.getListClients(q).then((res) => {
      if (res && !res.hasOwnProperty('error')) {
        this.#hasNextPage = res.pageInfo.hasNextPage;
        this.#endCursor = res.pageInfo.endCursor;
        this.#data = this.convertDataIntoHotTableFormat(clients.listClients);
        this.#hotInstance.loadData(this.#data);
      }
    })
  }

  hot() {
    this.#hotInstance = new Handsontable(this.#container, {
      licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
      data: this.#data,
      colHeaders: true,
      stretchH: 'all',
      height: TABLE_HEIGHT,
      width: '100vw',
      autoWrapRow: true,
      rowHeaders: true,
      language: ruRU.languageCode,
      columns: this.#clientColumns,
      columnSorting: true,
      fixedColumnsLeft: this.fixedColumnsLeft(),
      colWidth: this.colWidths(),
      filters: this.#filterFields,
      dropdownMenu: ['filter_by_condition', 'filter_action_bar'],
      invalidCellClassName: 'myInvalidClass',
      beforeRefreshDimensions() { return false; },

      beforeOnCellMouseDown(event, coords) {
        if (coords.row === -1 && event.target.nodeName === 'INPUT') {
          event.stopImmediatePropagation();
          this.deselectCell();
        }
      },

      afterScrollVertically: () => {
        const count = this.#data.length;
        const autoRowSizePlugin = this.#hotInstance.getPlugin('AutoRowSize');

        if (clients.isLoading) return;

        if (autoRowSizePlugin.getLastVisibleRow() === count - 1 && this.#hasNextPage) {
          this.loadMore();
        }
      },

      beforeFilter: (conditionsStack) => {
        if (conditionsStack.length == 0) return false;
        const index = conditionsStack[0].column;

        // filters [ { "name": "id", "condition": "greater", "value": "10", "value2": null } ]
        const name = this.#clientColumns[index].value;
        const condition = conditionsStack[0].conditions[0].name ? grid_constants.mathConditions.filter(el => el.shortTitle == conditionsStack[0].conditions[0].name)[0].title : '';
        const input = document.querySelector('.htFiltersMenuCondition .htUIInput input');
        const isVal2 = condition == 'between' || condition == 'notBetween';

        const value1 = condition == 'equal' ? input.value : conditionsStack[0].conditions[0].args[0];
        const value2 = isVal2 ? conditionsStack[0].conditions[0].args[1] : null;

        const obj = { name, condition, value: value1, value2 };

        this.#listFilters

        return false;
      },
    });
  }

  convertDataIntoHotTableFormat(list) {
    const res = [];

    list.forEach((el) => {
      const listInner = [];
      const columns = this.#clientColumns;

      columns.forEach(col => {
        for (const [key, val] of Object.entries(el)) {
          let v = val;
          if (key == col.value) {
            if (col.hasOwnProperties) {
              if (!v) {
                v = null;
                listInner.push(v);
                return;
              }
              v = val[col.field];
            }
            listInner.push(v);
          }
        }
      });

      listInner.push(el.queries);

      res.push(listInner);
    });

    return res;
  }

  init(args) {
    this.#container = args.container;
    this.#data = this.convertDataIntoHotTableFormat(args.data.nodes);
    this.#hasNextPage = args.data.pageInfo.hasNextPage;
    this.#endCursor = args.data.pageInfo.endCursor;

    this.hot();
  }
}

const clientsHotTable = new ClientsHotTable();

export default clientsHotTable;
