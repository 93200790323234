import * as apolloQueries from '../clients/queries.js';
import errorHandler from '@/lib/ErrorHandler';
import store from '@/store';
import clientsHotTable from '@/lib/handsontable/clients/table.js';
import { apolloClient } from '@/lib/apollo';

export class ClientsDataMapper {
  #apolloClient = null;
  #listClients = [];
  #callbackStatus = null;
  #isLoading = false;

  constructor(apolloClient) {
    this.#apolloClient = apolloClient;
  }

  get isLoading() {
    return this.#isLoading;
  }

  set isLoading(val) {
    this.#isLoading = val;
  }

  get listClients() {
    return this.#listClients;
  }

  get callbackStatus() {
    return this.#callbackStatus;
  }

  set callbackStatus(val) {
    this.#callbackStatus = val;
  }

  async getListClients(args) {
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_clients,
      notifyOnNetworkStatusChange: true,  
      variables: {
        first: args.first,
        fields: args.fields,
        orders: args.orders,
        cursor: args.cursor
      },
    })
    .then((data, loading) => {
      if (!loading) {
        if (data == null) {
          this.#isLoading = false;
          this.fireCallbackDone();
          return errorHandler.handleError('Не удалось загрузить список клиентов', args);
        }

        this.#isLoading = false;
        this.fireCallbackDone();

        const res = data.data.clients.nodes;
        this.#listClients = this.#listClients.length == 0 ? res : [...this.#listClients, ...res];
        store.commit('clients/setClients', this.#listClients);
        return data.data.clients;
      }
    })
    .catch((error) => {
      this.#isLoading = false;
      this.fireCallbackDone();
      return errorHandler.handleError(error, args);
    });

    return x;
  }

  fireCallbackDone() {
    if (!this.#callbackStatus) return;

    this.#callbackStatus({
      isLoading: this.isLoading,
    })
  }

  loadClientsData(args) {
    this.#isLoading = true;
    this.fireCallbackDone();

    this.getListClients(args.query).then((res) => {
      if (res && !res.hasOwnProperty('error')) {
        clientsHotTable.init({ ...args.table, data: res });
      }
    })
  }
}

const clients = new ClientsDataMapper(apolloClient);

export default clients;
